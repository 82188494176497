<template>
  <div>
      <b-card>
        <b-row>
          <b-col>
            <b-form-select v-model="os" :options="options" />
          </b-col>
            <b-col>
              <b-input-group>
                <cleave placeholder="1.3.4" :raw="false" class="form-control" v-model="version" :options="cleaveOptions" />
                <b-button-group>
                  <b-button :disabled="version.length != 5" variant="warning" @click="add">Add</b-button>
                  <b-button variant="primary" @click="fetchVersions">List</b-button>
                </b-button-group>
              </b-input-group>
            </b-col>
        </b-row>
      </b-card>
    <b-row>
      <b-col>
        <b-card title="Android">
          <b-table :current-page="currentPageAndroid" per-page="5" :fields="fields" :items="androidVersions">
            <template #cell(actions)="data">
               <b-button-group v-show="data.item.added">
                  <b-button @click="() => save(data.item)" variant="warning" size="sm">Save</b-button>
                  <b-button @click="() => remove(data.item)" variant="danger" size="sm">Delete</b-button>
                </b-button-group>
              <b-button v-show="!data.item.added" @click="() => deleteVersion(data.item)" variant="danger" size="sm">Remove</b-button>
            </template>
          </b-table>
          <b-pagination v-model="currentPageAndroid" :total-rows="androidVersions.length" per-page="5" />
        </b-card>
      </b-col>
      <b-col>
        <b-card title="iOS">
          <b-table :current-page="currentPageIOS" per-page="5" :fields="fields" :items="iosVersions">
            <template #cell(actions)="data">
               <b-button-group v-show="data.item.added">
                  <b-button @click="() => save(data.item)" variant="warning" size="sm">Save</b-button>
                  <b-button @click="() => remove(data.item)" variant="danger" size="sm">Delete</b-button>
                </b-button-group>
              <b-button v-show="!data.item.added" @click="() => deleteVersion(data.item)" variant="danger" size="sm">Remove</b-button>
            </template> 
          </b-table>
          <b-pagination v-model="currentPageIOS" :total-rows="iosVersions.length" per-page="5" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component';
import StaticOptions from '@/common/options/StaticOptions';

export default {
    components: {
        Cleave,
    },
  mounted() {
    this.fetchVersions();
  },
  data() {
    return {
      cleaveOptions: { blocks: [1, 1, 1], delimiters: ['.'], numericOnly: true },
      version: '',
      os: 0,
      options: StaticOptions.appPlatformOptions, 
      perPage: 5,
      currentPageAndroid: 1,
      currentPageIOS: 1,
      fields: ['version', 'actions'] }
  },
  methods: {
    fetchVersions() {
      this.$store.dispatch('fetchAppVersions');
    },
    add() {
        this.$store.commit('addAppVersion', { v: this.version, o: this.os });
    },
    remove(item) {
        this.$store.commit('removeAppVersion', item);
    },
    save(item) {
      this.$store.dispatch('saveAppVersion', item);
    },
    deleteVersion(item) {
      this.$store.dispatch('deleteAppVersion', item);
    },
  },
  computed: {
    iosVersions() {
      return this.$store.getters.iosVersions;
    },
    androidVersions() {
      return this.$store.getters.androidVersions;
    },
    
  },
}
</script>
